/**
 * 规则：
 * 一、例如：index/index，shop/index以index结尾的，path和name默认去除index
 * 二、例如：shop/list，默认生成name为shop_list（如果结尾为index，例如shop/index则是shop）
 * 三、填写后不会自动生成
 * **/
let routes = [{
		path: '/',
		name: 'layout',
		param: false,
		redirect: {
			name: 'index/index'
		},
		component: 'layout',
		children: [{
			meta: {
				title: '首页'
			},
			component: 'index/index',
			param: false
		}, {
			meta: {
				title: '用户管理'
			},
			component: 'user/index',
			param: false
		}, {
			meta: {
				title: '用户领取记录'
			},
			component: 'user/coupon',
			param: 'user_id'
		}, {
			meta: {
				title: '会员列表'
			},
			component: 'member/index',
			param: false
		}, {
			meta: {
				title: '会员设置'
			},
			component: 'member/setting',
			param: false
		}, {
			meta: {
				title: '充值订单'
			},
			component: 'member.order/index',
			param: false
		}, {
			meta: {
				title: '分销商管理'
			},
			component: 'member.dealer.user/index',
			param: false
		}, {
			meta: {
				title: '添加分销商'
			},
			component: 'member.dealer.user/add',
			param: false
		}, {
			meta: {
				title: '分销商设置'
			},
			component: 'member.dealer.setting/index',
			param: false
		}, {
			meta: {
				title: '商家用户'
			},
			component: 'user.merchant/index',
			param: false
		}, {
			meta: {
				title: '商家优惠券'
			},
			component: 'user.merchant/coupon',
			param: 'merchant_user_id'
		}, {
			meta: {
				title: '优惠券管理'
			},
			component: 'coupon/index',
			param: false
		}, {
			meta: {
				title: '优惠券详情'
			},
			component: 'coupon/detail',
			param: 'coupon_id'
		}, {
			meta: {
				title: '领取记录'
			},
			component: 'coupon/record',
			param: false
		}, {
			meta: {
				title: '举报管理'
			},
			component: 'report/index',
			param: false
		}, {
			meta: {
				title: '素材管理'
			},
			component: 'content.material/index',
			param: false
		}, {
			meta: {
				title: '小程序设置'
			},
			component: 'wxapp/setting',
			param: false
		}, {
			meta: {
				title: '管理员列表'
			},
			component: 'store.user/index',
			param: false
		}, {
			meta: {
				title: '管理员添加'
			},
			component: 'store.user/add',
			param: false
		}, {
			meta: {
				title: '管理员编辑'
			},
			component: 'store.user/edit',
			param: 'store_user_id'
		}, {
			meta: {
				title: '个人中心'
			},
			component: 'store.user/renew',
			param: false
		}, {
			meta: {
				title: '权限列表'
			},
			component: 'store.access/index',
			param: false
		}, {
			meta: {
				title: '角色列表'
			},
			component: 'store.role/index',
			param: ''
		}, {
			meta: {
				title: '角色添加'
			},
			component: 'store.role/add',
			param: false
		}, {
			meta: {
				title: '角色编辑'
			},
			component: 'store.role/edit',
			param: 'role_id'
		}, {
			meta: {
				title: '平台设定'
			},
			component: 'setting/setting',
			param: false
		}, {
			meta: {
				title: '404错误'
			},
			component: 'error/404',
			param: false
		}]
	},
	{
		meta: {
			title: '登录页'
		},
		component: 'passport/login',
		param: false
	},
	{
		path: '*',
		redirect: {
			name: 'index/index'
		},
	}
]

// 获取路由信息方法
let getRoutes = function() {
	// 自动生成路由
	createRoute(routes)
	console.log(routes)
	return routes
}

// 自动生成路由
function createRoute(arr) {
	for (let i = 0; i < arr.length; i++) {
		if (!arr[i].component) return
		// 去除index
		let val = arr[i].component.replace(/\./g, '/')
		// 生成name
		arr[i].name = arr[i].component
		if (arr[i].param) {
			// 生成path
			arr[i].path = '/' + val + '/:' + arr[i].param
		} else {
			// 生成path
			arr[i].path = '/' + val
		}

		// 自动生成component
		let componentFun = import(`../../views/${val}.vue`)
		arr[i].component = () => componentFun
		if (arr[i].children && arr[i].children.length > 0) {
			createRoute(arr[i].children)
		}
	}
}

export default getRoutes()
